video {
    width: 100%;
}

#mapVideo{
    position: absolute;
    top: 10px;
    left: 0px;
    width: 100%;
    clip-path: url(#overlayHomePage);
    -webkit-clip-path: url(#overlayHomePage);
}

#lineMap {
    position: absolute;
    top: 0px;
    left: 0px;
    fill: none;
    stroke: white;
    stroke-width: 3;
    stroke-dasharray: 260;
    transform: translate(0px, 6px) scale(1.02, 1.02);
    animation: dash 5s alternate-reverse infinite;
}

@keyframes dash {
    to {
        stroke-dashoffset: 1000;
    }
}

#landingMap {
    position: relative;
    width: 100%;
}