/*
* Prefixed by https://autoprefixer.github.io
* PostCSS: v8.4.14,
* Autoprefixer: v10.4.7
* Browsers: last 4 version
*/

.mapContainer {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-flex: 0.95;
        -ms-flex-positive: 0.95;
            flex-grow: 0.95;
    -webkit-transform: scale(1.2) translateY(-10vw);
        -ms-transform: scale(1.2) translateY(-10vw);
            transform: scale(1.2) translateY(-10vw);
    z-index: 1;
    position: relative;
    pointer-events: none;
}

.map {
    width: 50vw;
    min-width: 450px;
    margin: 0px 6vw;

    @media screen and (max-width: 500px) {
        margin-top: -5vh;
    }

    @media screen and (max-width: 400px) {
        min-width: 425px;
    }
}

.contentContainer {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    position: relative;
    z-index: 2;
    -webkit-box-flex: 1;
        -ms-flex-positive: 1;
            flex-grow: 1;
    padding-bottom: 30px;
}

.button {
    position: absolute;
    bottom: 0px;
    width: 100vw;
    background-color: #00BAB5;
    text-transform: uppercase;
    padding: 10px;
    border: none;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    height: 40px;
    cursor: pointer;
    text-decoration: none;
    color: black;
}

a {
    color: black;
}

.poweredContainer {
    width: 100%;
    font-size: 0.8em;
    position: relative;
    z-index: 2;
}

.googleLogo {
    width: 80px;
}

.georgia {
    font-family: 'Georgia-Italic', serif;
}

.poweredContainer {
    margin: 1em 0px;
}

@media screen and (max-height: 700px) and (min-width: 1000px) {
    .mapContainer {
        margin-top: -10dvh;
    }
}

@media screen and (min-height: 900px) and (min-width: 1800px) {
    .mapContainer {
        margin-top: 0px;
    }

    .map {
        width: 55vw;
        margin: 0px 4vw;
    }

    .button {
        font-size: 1.2em;
        height: 50px;
    }
}

@media screen and (max-width: 1200px) {
    .mapContainer {
        pointer-events: auto;
    }
}